<template>
    <div v-if="$store.state.poi_data">
      <div v-for="(station,ind) in $store.state.poi_data" :key="ind+'poly'">
        <div v-if="station.geom">
          <l-geo-json 
          :options="{ style: { zIndex: 1 } }"
          :geojson="{
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: JSON.parse(station.geom),
                  properties: station,
                },],
              }"
          :optionsStyle="{'fillColor': 'transparent','color': polygon_color[array_of_poly_values.indexOf(station.geolocation)],}"    
                              
              ></l-geo-json>
        </div>
     
      </div>
    </div>
</template>


<script>
//import L from "leaflet";
import axios_django from "../axios-auth";

export default {
  name:'ParcelsPoly',
  components: {
  
  },
  data() {
    return {

      poi_data:null,
      array_of_poly_values:['τοπογραφική μέτρηση με συντεταγμένες (πολύγωνο)','κτηματογράφηση (πολύγωνο)','προσέγγιση από παλαιό μη εξαρτημένο σχέδιο (πολύγωνο)','προσεγγιση σε ορθοφωτοχάρτη (πολύγωνο)'],
      polygon_color:['#2feaed','#5bd252','#ff7f00','#fb9a99']
    }
  },
 
  mounted(){
    
    this.$nextTick(() => {
  
      //this.$refs.map1.mapObject.sync(this.$refs.map2.mapObject)
      //this.$refs.map2.mapObject.sync(this.$refs.map1.mapObject)
    });
  },
  async created(){
   
    
    this.loadchurch()
  
  },
  methods:{
    loadchurch(){
      var token=localStorage.getItem('token')
      if(!this.$store.state.poi_data){
      axios_django.get('sydapzak/poi/',{headers: {Authorization: `Bearer ${token}`}}).then(res=>{
        //console.log(res.data)
        this.poi_data=res.data
        this.$store.commit('set', ['poi_data', this.poi_data])
      })
      }
    },
    
  
  }
 

};
</script>
